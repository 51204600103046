import {Component, Input} from "@angular/core";
import {FormGroup, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {IFormQuestionModel} from "@shared/components/dynamic-form/models/form-model.interface";
import {DropdownModule} from "primeng/dropdown";
import {RadioButtonModule} from "primeng/radiobutton";

@Component({
  selector: "app-dynamic-radio",
  templateUrl: "./dynamic-radio.component.html",
  styleUrls: ["./dynamic-radio.component.css"],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    DropdownModule,
    RadioButtonModule
  ]
})
export class DynamicRadioComponent {
  @Input({ required: true }) field!: IFormQuestionModel;
  formName: FormGroup;

  constructor(private formgroupDirective: FormGroupDirective) {
    this.formName = formgroupDirective.control;
  }

  ngOnInit(){
    //Reactive Form does not recognize the field.value object as equal as options array object
    if (this.field.value) {
      this.formName.patchValue({answer: this.field.options!().find(el => el.id === this.field.value.id)}, {emitEvent: false})
    }
  }
}
