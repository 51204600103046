<form [formGroup]="formName">
  <div class="field col-{{field.col}}">
    <label [for]="field.fieldName">{{field.label}}</label>
    <p-calendar
      [formControlName]="field.fieldName!"
      [inputId]="field.fieldName"
      [showIcon]="true"
      dateFormat="dd/mm/yy"
      [readonlyInput]="true"
      [selectionMode]="field.dateSelectionMode || 'single'"></p-calendar>
  </div>
</form>
