import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {RoutingPathConfig} from "@shared/configs/routing-path.config";

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  #http = inject(HttpClient);
  readonly #path = `${environment.BASE_URL}${RoutingPathConfig.SUPPORT}`;

  createTicket(ticket: any) {
    return this.#http.post(this.#path, ticket);
  }
}
