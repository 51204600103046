import {Component, Input} from '@angular/core';
import {InputOtpModule} from "primeng/inputotp";
import {IFormQuestionModel} from "@shared/components/dynamic-form/models/form-model.interface";
import {FormGroup, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-dynamic-otp',
  standalone: true,
  imports: [
    InputOtpModule,
    ReactiveFormsModule
  ],
  templateUrl: './dynamic-otp.component.html',
  styleUrl: './dynamic-otp.component.scss'
})
export class DynamicOtpComponent {

  @Input({required: true}) field!: IFormQuestionModel;
  formName: FormGroup;

  constructor(private formgroupDirective: FormGroupDirective) {
    this.formName = formgroupDirective.control;
  }

}
