import {Component, EventEmitter, Input, OnChanges, OnInit, output, Output, SimpleChanges} from "@angular/core";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  IFormModel,
  IFormQuestionModel,
  IFormRuleModel
} from "src/app/shared/components/dynamic-form/models/form-model.interface";
import {DynamicFieldComponent} from "src/app/shared/components/dynamic-form/dynamic-field/dynamic-field.component";
import {DynamicErrorComponent} from "src/app/shared/components/dynamic-form/dynamic-error/dynamic-error.component";
import {CommonModule} from "@angular/common";
import {ButtonModule} from "primeng/button";
import {ILooseObject} from "@shared/models/loose-object.interface";

@Component({
  selector: "app-dynamic-form",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.scss"],
  standalone: true,
  imports: [
    DynamicFieldComponent,
    ReactiveFormsModule,
    DynamicErrorComponent,
    CommonModule,
    ButtonModule,
  ]
})
export class DynamicFormComponent implements OnInit, OnChanges {
  @Input({required: true}) set model(model: IFormModel){
    this.#model = Object.entries(model)
      .sort(([,a],[,b]) => a.order - b.order)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.buildForm();
  };
  @Input() submitLabel = 'Salva';
  @Input() submitIcon = 'save';
  @Input() readonly = false;
  @Input() edit = false;
  @Input() showActions = true;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  isReady = output<boolean>();

  #model!: IFormModel
  dynamicFormGroup!: FormGroup;
  fields: IFormQuestionModel[] = [];

  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    let readonly = changes["readonly"];
    if (readonly && !readonly?.currentValue) {
      this.dynamicFormGroup?.enable()
    }
    if (readonly && readonly.currentValue) {
      this.dynamicFormGroup?.disable()
    }
  }


  private buildForm() {
    const formGroupFields = this.getFormControlsFields();
    this.dynamicFormGroup = new FormGroup(formGroupFields);
    this.isReady.emit(true);
  }

  private getFormControlsFields() {
    const formGroupFields: ILooseObject = {};
    //TODO ADD CHECK FIELD EXIST
    this.fields = [];
    for (const field of Object.keys(this.#model)) {
      const fieldProps: IFormQuestionModel = this.#model[field];
      const validators = this.addValidator(fieldProps.rules);
      formGroupFields[field] = new FormControl({value: fieldProps.value, disabled: this.readonly}, validators);
      this.fields.push({...fieldProps, fieldName: field});
    }

    return formGroupFields;
  }

  private addValidator(rules?: IFormRuleModel): Validators {
    if (!rules) {
      return [];
    }

    return Object.keys(rules).map((rule) => {
      switch (rule) {
        case "required":
          return Validators.required;
        case "regex":
          return this.regexValidator(rules[rule] as RegExp)
        case "email":
          return Validators.email
      }
      return null
    });
  }

 regexValidator(regex:RegExp) {
    return (control:any) => {
      const isValid = regex.test(control.value);
      return isValid ? null : { invalidFormat: true };
    };
  }

  submit() {
    this.onSubmit.emit(this.dynamicFormGroup.getRawValue());
  }

  disableSingleField(field:string){
    this.dynamicFormGroup.controls[field].disable({ emitEvent: false });
  }

  enableSingleField(field:string){
    this.dynamicFormGroup.controls[field].enable({ emitEvent: false });
  }

  delete() {
    this.onDelete.emit();
  }

  isValid() {
    return this.dynamicFormGroup.valid
  }
}
