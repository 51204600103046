import {Component, inject} from '@angular/core';
import {RouterLink} from '@angular/router';
import {RoutingPathConfig} from '@shared/configs/routing-path.config';
import {ButtonModule} from 'primeng/button';
import {DialogService} from "primeng/dynamicdialog";
import {environment} from "../../../environments/environment";
import {TicketModalComponent} from "@shared/components/ticket-modal/ticket-modal.component";

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [ButtonModule, RouterLink],
  template: `
    <div class="h-screen w-full flex justify-content-center align-items-center gap-4 flex-column">
    <img src="../../../assets/Logo.svg" alt="ESG LOGO" width="180">
    <div class="border-round-xl p-2" style="background:linear-gradient(180deg, rgba(65,72,255, 0.4) 10%, rgba(65,72,255, 0) 30%);">
        <div class="surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center border-round-2xl text-center" >
           <h3 class="mb-2">404</h3>
           <h1>Pagina non trovata</h1>
           <span class="mt-4">
           Ci dispiace, ma la pagina che stai cercando<br/> potrebbe essere stata rimossa o temporaneamente non disponibile.
           </span>
           <div class="my-4 flex flex-column gap-4">
            <span>Verifica di aver digitato correttamente l'URL</span>
             <button
               pButton
               class="p-button-text my-4"
               label="Ritorna alla Home"
               [routerLink]="['/',RoutingPathConfig.DASHBOARD]">
             </button>
          <span>
            Se hai seguito un link, potrebbe essere rotto; segnalacelo per favore.
          </span>
          </div>
          <span>Se il problema persiste o se hai bisogno di ulteriore assistenza, contatta il nostro servizio clienti <br/> <a href="{{supportEmail}}">{{supportEmail}}</a> oppure</span>
          <button
            pButton
            class="p-button-text my-4"
            label="Invia una segnalazione"
            (click)="openModal()">
          </button>        </div>
      </div>
</div>
  `,
  styles: ``
})
export class NotFoundComponent {

  #dialogService = inject(DialogService)

  RoutingPathConfig = RoutingPathConfig;
  supportEmail = environment.SUPPORT_EMAIL;

  openModal() {
    this.#dialogService.open(TicketModalComponent, {
      header: 'Invia nuova segnalazione'
    })
  }

}
