import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {DropdownModule} from "primeng/dropdown";
import {PaginatorModule} from "primeng/paginator";
import {IFormQuestionModel} from "src/app/shared/components/dynamic-form/models/form-model.interface";
import {FormGroup, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";
import {MultiSelectModule} from "primeng/multiselect";

@Component({
  selector: 'app-dynamic-multiselect',
  standalone: true,
  imports: [CommonModule, DropdownModule, PaginatorModule, ReactiveFormsModule, MultiSelectModule],
  template: `
    <form [formGroup]="formName">
      <div class="flex flex-column gap-2 my-2  col-{{field.col}}">
        <label [for]="field.fieldName">{{field.label}}</label>
        <p-multiSelect [formControlName]="field['fieldName']!"
                       [options]="field.options!()"
                       [optionLabel]="field.optionLabel || 'value'"
                       [optionValue]="field.optionValue || undefined"></p-multiSelect>
      </div>
    </form>
  `,
  styles: [
  ]
})
export class DynamicMultiselectComponent {

  @Input({required: true}) field!: IFormQuestionModel;
  formName: FormGroup;

  constructor(
      private formGroupDirective: FormGroupDirective) {
    this.formName = formGroupDirective.control;
  }

}
