import {Injectable} from '@angular/core';
import {environment} from "src/environments/environment";
import {IClient} from "src/app/shared/models/client.interface";
import {HttpClient} from "@angular/common/http";
import {IPagination} from "@shared/models/pagination.interface";
import {IUser} from "@shared/models/user.interface";
import {IContract} from "@shared/models/contract.interface";
import {SERVICE} from "@shared/enums/service.enum";
import {ITenant} from "@shared/models/tenant.interface";

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  private readonly URL = `${environment.BASE_URL}customer`
  private readonly partnersURL = `${this.URL}/partner`

  constructor(private http: HttpClient) {
  }

  getClients$(skip: number, take: number, id?: string) {
    const path = id ? `${this.URL}/${id}` : this.URL

    return this.http.get<IPagination<IClient>>(path, {
      params: {
        skip,
        take
      }
    })
  }

  getPartnerClients$(partnerId: string, skip: number, take: number) {
    return this.http.get<IPagination<IClient>>(`${this.partnersURL}/${partnerId}/customer`, {
      params: {
        skip,
        take
      }
    })
  }

  getPartners$(skip: number, take: number) {
    return this.http.get<IPagination<IClient>>(this.partnersURL, {
      params: {
        skip,
        take
      }
    })
  }

  delete$(id: string) {
    return this.http.delete<void>(`${this.URL}/${id}`)
  }

  private create$(client: IClient) {
    return this.http.post<IClient>(this.URL, client)
  }

  private update$(client: IClient) {
    return this.http.patch<IClient>(`${this.URL}/${client.id}`, client)
  }

  save$(client: IClient) {
    if (client.id) {
      return this.update$(client);
    } else {
      return this.create$(client);
    }
  }

  get$(id: string) {
    return this.http.get<IClient>(`${this.URL}/${id}`)
  }

  saveUser$(id: string, user: IUser) {
    if (user.id) {
      return this.#updateUser$(id, user);
    } else {
      return this.#createUser$(id, user);
    }
  }

  #createUser$(id: string, user: IUser) {
    return this.http.post<IUser>(`${this.URL}/${id}/user`, user)
  }

  #updateUser$(id: string, user: IUser) {
    return this.http.patch<IUser>(`${this.URL}/${id}/user`, user)
  }

  deleteUser$(id: string, clientId: string) {
    return this.http.delete<void>(`${this.URL}/${clientId}/user/${id}`)
  }

  sendUserWelcome(id: string, customerId: string) {
    return this.http.get<IClient>(`${this.URL}/${customerId}/user/${id}/welcome-email`)
  }

  getUsersList$(id: string) {
    return this.http.get<IUser[]>(`${this.URL}/${id}/users`)
  }

  saveContract$(contract: IContract, clientId: string) {
    if (contract.id) {
      return this.#updateContract$(contract, clientId);
    } else {
      return this.#createContract$(contract, clientId);
    }
  }

  getContractsList$(clientId: string) {
    return this.http.get<IContract[]>(`${this.URL}/${clientId}/contract`)
  }

  getContractsListByService$(service: SERVICE) {
    return this.http.get<IContract[]>(`${this.URL}/contract/${service}`)
  }

  #createContract$(contract: IContract, clientId: string) {
    return this.http.post<IContract>(`${this.URL}/${clientId}/contract`, contract)
  }

  #updateContract$(contract: IContract, clientId: string) {
    return this.http.patch<IContract>(`${this.URL}/${clientId}/contract`, contract)
  }

  deleteContract$(id: string, clientId: string) {
    return this.http.delete<void>(`${this.URL}/${clientId}/contract/${id}`)
  }

  saveTenant$(tenant: Partial<ITenant>, fileToUpload: File, clientId: string) {
    if (tenant.id) {
      return this.#updateTenant$(tenant, fileToUpload, clientId);
    } else {
      return this.#createTenant$(tenant, fileToUpload, clientId);
    }
  }

  #createTenant$(tenant: Partial<ITenant>, fileToUpload: File, clientId: string) {
    const formData: FormData = new FormData();
    formData.append('upload', fileToUpload, fileToUpload?.name);
    formData.append('attached', JSON.stringify(tenant));
    return this.http.post<any>(`${this.URL}/${clientId}/tenant`, formData)
  }

  #updateTenant$(tenant: Partial<ITenant>, fileToUpload: File, clientId: string) {
    const formData: FormData = new FormData();
    formData.append('upload', fileToUpload, fileToUpload?.name);
    formData.append('attached', JSON.stringify(tenant));
    return this.http.patch<any>(`${this.URL}/${clientId}/tenant/${tenant.id}`, formData)
  }

  deleteTenant$(clientId: string) {
    return this.http.delete<void>(`${this.URL}/${clientId}/tenant`)
  }

  getTenant(partnerId: string) {
    return this.http.get<ITenant>(`${this.URL}/${partnerId}/tenant`)
  }

}
