<router-outlet></router-outlet>
<p-confirmDialog #cd>
  <ng-template let-message pTemplate="headless">
    <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
      <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
        <i class="pi {{message.icon}} text-5xl"></i>
      </div>
      <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
      <p class="mb-0">{{ message.message }}</p>
      <div class="flex align-items-center gap-2 mt-4">
        <button (click)="cd.accept()" class="w-8rem" label="{{message.acceptLabel}}" pButton></button>
        <button (click)="cd.reject()" class="p-button-outlined w-8rem " label="Annulla" pButton></button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
<p-toast></p-toast>
@if (isLoading$ | async) {
  <app-spinner></app-spinner>
}

