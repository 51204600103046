import {RoutingPathConfig} from "src/app/shared/configs/routing-path.config";
import {checkHaveServices, checkSectionRole, SectionRole} from "src/app/shared/configs/role.config";
import {MenuItem} from "primeng/api";
import {EsgAssessmentRoutingPathConfig} from "@esg-assessment/shared/configs/esg-assessment-routing.config";
import {ROLE} from "@shared/enums/role.enum";
import {ManagementControlRoutingPathConfig} from "@management-control/shared/configs/management-control-routing.config";
import {IUser} from "@shared/models/user.interface";
import {SERVICE} from "@shared/enums/service.enum";
import {
  MaterialityAnalysisRoutingConfig
} from "@materiality-analysis/shared/configs/materiality-analysis-routing.config";
import {environment} from "../../../environments/environment";
import {AdminRoutingPathConfig} from "../../features/admin/shared/configs/admin.config";

export const buildMain = (role: ROLE): MenuItem[] => {
  return [
    {
      label: 'Profilo',
      routerLink: [`./${RoutingPathConfig.USER}`],
      icon: "pi pi-user",
      routerLinkActiveOptions: {exact: true}
    },
    {
      label: 'Clienti',
      routerLink: [`./${RoutingPathConfig.CUSTOMERS}`],
      icon: "pi pi-users",
      visible: checkSectionRole(SectionRole.ClientList, role),
      routerLinkActiveOptions: {exact: true}
    },
    {
      label: 'Partner',
      routerLink: [`./${RoutingPathConfig.PARTNERS}`],
      icon: "pi pi-users",
      visible: checkSectionRole(SectionRole.ClientList, role),
      routerLinkActiveOptions: {exact: true}
    },
    {
      label: 'Dashboard',
      routerLink: [`./${RoutingPathConfig.DASHBOARD}`],
      icon: "pi pi-home",
      visible: checkSectionRole(SectionRole.Dashboard, role),
      routerLinkActiveOptions: {exact: true}
    },
    {
      label: 'Lista contratti',
      routerLink: [`./${RoutingPathConfig.ADMIN}/${AdminRoutingPathConfig.CONTRACT}`],
      icon: "pi pi-wallet",
      visible: checkSectionRole(SectionRole.SysAdminContractList, role),
      routerLinkActiveOptions: {exact: true}
    },
  ]
}

export const buildServiceMenuAdmin = (role: ROLE): MenuItem[] => {
  return [
    {
      label: 'ESG Assessment',
      items: [
        {
          label: 'Topic',
          routerLink: [`./${RoutingPathConfig.ESG_ASSESTMENT}/${EsgAssessmentRoutingPathConfig.TOPICS}`],
          visible: checkSectionRole(SectionRole.ESGTopicList, role),
          routerLinkActiveOptions: {exact: true}
        },
        {
          label: 'Questionari',
          routerLink: [`./${RoutingPathConfig.ESG_ASSESTMENT}/${EsgAssessmentRoutingPathConfig.SURVEYS}`],
          visible: checkSectionRole(SectionRole.ESGSurveyList, role),
          routerLinkActiveOptions: {exact: true}
        }
      ],
      visible: environment.SERVICE_ENABLED.includes(SERVICE.ESG_ASSESSMENT)
    },
    {
      label: 'Controllo di gestione',
      items: [
        {
          label: 'Topic',
          routerLink: [`./${RoutingPathConfig.MANAGEMENT_CONTROL}/${ManagementControlRoutingPathConfig.TOPICS}`],
          visible: checkSectionRole(SectionRole.McTopicList, role),
          routerLinkActiveOptions: {exact: true}
        },
        {
          label: 'Questionari',
          routerLink: [`./${RoutingPathConfig.MANAGEMENT_CONTROL}/${ManagementControlRoutingPathConfig.SURVEYS}`],
          visible: checkSectionRole(SectionRole.McSurveyList, role),
          routerLinkActiveOptions: {exact: true}
        }
      ],
      visible: environment.SERVICE_ENABLED.includes(SERVICE.MANAGEMENT_CONTROL)
    },
    {
      label: 'Analisi di materialità',
      items: [
        {
          label: 'Struttura',
          routerLink: [`./${RoutingPathConfig.MATERIALITY_ANALYSIS}/${MaterialityAnalysisRoutingConfig.STRUCTURE}`],
          visible: checkSectionRole(SectionRole.MaStructureList, role),
          routerLinkActiveOptions: {exact: true}
        },
        {
          label: 'Questionari',
          routerLink: [`./${RoutingPathConfig.MATERIALITY_ANALYSIS}/${MaterialityAnalysisRoutingConfig.SURVEYS}`],
          visible: checkSectionRole(SectionRole.MaSurveyList, role),
          routerLinkActiveOptions: {exact: true}
        },
      ],
      visible: environment.SERVICE_ENABLED.includes(SERVICE.MATERIALITY_ANALYSIS)
    },
  ]
}

export const buildServiceMenuPartner = (role: ROLE): MenuItem[] => {
  return [
    {
      label: 'ESG Assessment',
      routerLink: [`./${RoutingPathConfig.ESG_ASSESTMENT}/${EsgAssessmentRoutingPathConfig.SURVEYS}`],
      visible: checkSectionRole(SectionRole.ESGSurveyList, role),
      routerLinkActiveOptions: {exact: true}
    },
    {
      label: 'Controllo di gestione',
      routerLink: [`./${RoutingPathConfig.MANAGEMENT_CONTROL}/${ManagementControlRoutingPathConfig.SURVEYS}`],
      visible: checkSectionRole(SectionRole.McSurveyList, role),
      routerLinkActiveOptions: {exact: true}
    },
    {
      label: 'Analisi Materialità',
      routerLink: [`./${RoutingPathConfig.MATERIALITY_ANALYSIS}/${MaterialityAnalysisRoutingConfig.SURVEYS}`],
      visible: checkSectionRole(SectionRole.MaSurveyList, role),
      routerLinkActiveOptions: {exact: true}
    }
  ]
}


export const buildServiceMenuOperator = (currentUser: IUser): MenuItem[] => {
  return [
    {
      label: 'ESG Assessment',
      routerLink: [`/${RoutingPathConfig.ESG_ASSESTMENT}/${EsgAssessmentRoutingPathConfig.SURVEYS}`],
      visible: checkSectionRole(SectionRole.ESGSurveyList, currentUser.role!)
        && checkHaveServices(SERVICE.ESG_ASSESSMENT, currentUser)
        && environment.SERVICE_ENABLED.includes(SERVICE.ESG_ASSESSMENT),
      routerLinkActiveOptions: {exact: true}
    },
    {
      label: 'Controllo di gestione',
      routerLink: [`/${RoutingPathConfig.MANAGEMENT_CONTROL}/${ManagementControlRoutingPathConfig.SURVEYS}`],
      visible: checkSectionRole(SectionRole.ESGSurveyList, currentUser.role!)
        && checkHaveServices(SERVICE.MANAGEMENT_CONTROL, currentUser)
        && environment.SERVICE_ENABLED.includes(SERVICE.MANAGEMENT_CONTROL),
      routerLinkActiveOptions: {exact: true}
    },
    {
      label: 'Analisi Materialità',
      routerLink: [`/${RoutingPathConfig.MATERIALITY_ANALYSIS}/${MaterialityAnalysisRoutingConfig.SURVEYS}`],
      visible: checkSectionRole(SectionRole.MaSurveyList, currentUser.role!)
        && checkHaveServices(SERVICE.MATERIALITY_ANALYSIS, currentUser)
        && environment.SERVICE_ENABLED.includes(SERVICE.MATERIALITY_ANALYSIS),
      routerLinkActiveOptions: {exact: true}
    }
  ]
}

