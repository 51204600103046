import {AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild, ViewContainerRef} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {IFormQuestionModel} from "@shared/components/dynamic-form/models/form-model.interface";
import {dynamicFieldComponentFactory} from "@shared/components/dynamic-form/dynamic-field/dynamic-field.config";

@Component({
  selector: "app-field-input",
  templateUrl: "./dynamic-field.component.html",
  styleUrls: ["./dynamic-field.component.scss"],
  standalone: true
})
export class DynamicFieldComponent implements AfterViewInit{

  @ViewChild('dynamicInputContainer', { read: ViewContainerRef}) dynamicInputContainer!: ViewContainerRef;

  afterViewInit = false;
  #field!: IFormQuestionModel;

  @Input({required: true}) set field(field: IFormQuestionModel) {
    this.#field = field;
    if (this.afterViewInit) {
      this.registerDynamicField();
    }
  };

  formName!: FormGroup;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.afterViewInit = true;
    this.registerDynamicField();
  }

  private registerDynamicField() {
    this.dynamicInputContainer.clear();
    const componentInstance = dynamicFieldComponentFactory[this.#field.type]
    const dynamicComponent = this.dynamicInputContainer.createComponent(componentInstance)
    dynamicComponent.setInput('field', this.#field);
    this.cd.detectChanges();
  }


}
