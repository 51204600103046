import {computed, Injectable, signal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  #accessDeniedError = signal<string | null>(null);
  accessDeniedError = computed(() => this.#accessDeniedError())

  constructor() {
  }

  setAccessDeniedError(error: string) {
    this.#accessDeniedError.set(error);
  }
}
