import {ROLE} from "src/app/shared/enums/role.enum";
import {SERVICE} from "@shared/enums/service.enum";
import {IContract} from "@shared/models/contract.interface";
import {IUser} from "@shared/models/user.interface";
import {environment} from "../../../environments/environment";

export enum SectionRole {
  PartnerMenu,
  OperatorMenu,
  AdminMenu,
  ClientList,
  ESGTopicList,
  ESGSurveyList,
  ESGShowClientDataTable,
  TenantTab,
  ESGAddSurvey,
  ESGEditSurvey,
  McEditSurvey,
  ClientEdit,
  ClientAdd,
  ContractEdit,
  ContractAdd,
  Dashboard,
  McSurveyList,
  McTopicList,
  McAddSurvey,
  McAddYear,
  MaStructureList,
  MaTopic,
  MaFill,
  MaCreation,
  MaSurveyList,
  McCard,
  MaCard,
  EsgCard,
  NfrCard,
  ClientDelete,
  ContractDelete,
  McAddDashboardElement,
  SysAdminContractList,
  SysAdminImpersonate
}

export const SectionRoleDict: Record<SectionRole, ROLE[]> = {
  [SectionRole.PartnerMenu]: [ROLE.PARTNER],
  [SectionRole.OperatorMenu]: [ROLE.OPERATOR],
  [SectionRole.AdminMenu]: [ROLE.ADMIN],

  [SectionRole.ClientList]: [ROLE.ADMIN, ROLE.PARTNER, ROLE.SYSTEM_ADMIN],
  [SectionRole.ClientEdit]: [ROLE.ADMIN, ROLE.PARTNER],
  [SectionRole.ClientAdd]: [ROLE.ADMIN, ROLE.PARTNER],
  [SectionRole.ContractEdit]: [ROLE.ADMIN, ROLE.PARTNER],
  [SectionRole.ContractAdd]: [ROLE.ADMIN, ROLE.PARTNER],
  [SectionRole.TenantTab]: [ROLE.ADMIN],
  [SectionRole.ClientDelete]: [ROLE.ADMIN],
  [SectionRole.ContractDelete]: [ROLE.ADMIN],
  [SectionRole.Dashboard]: [ROLE.OPERATOR],

  [SectionRole.SysAdminContractList]: [ROLE.SYSTEM_ADMIN],
  [SectionRole.SysAdminImpersonate]: [ROLE.SYSTEM_ADMIN],

  [SectionRole.EsgCard]: [ROLE.PARTNER, ROLE.OPERATOR],
  [SectionRole.ESGTopicList]: [ROLE.ADMIN],
  [SectionRole.ESGSurveyList]: [ROLE.OPERATOR, ROLE.ADMIN, ROLE.PARTNER],

  [SectionRole.ESGShowClientDataTable]: [ROLE.ADMIN, ROLE.PARTNER],
  [SectionRole.ESGAddSurvey]: [ROLE.OPERATOR, ROLE.PARTNER],
  [SectionRole.ESGEditSurvey]: [ROLE.OPERATOR, ROLE.PARTNER],

  [SectionRole.McCard]: [ROLE.PARTNER, ROLE.OPERATOR],
  [SectionRole.McTopicList]: [ROLE.ADMIN],
  [SectionRole.McEditSurvey]: [ROLE.OPERATOR, ROLE.PARTNER],
  [SectionRole.McSurveyList]: [ROLE.OPERATOR, ROLE.ADMIN, ROLE.PARTNER],
  [SectionRole.McAddYear]: [ROLE.OPERATOR, ROLE.PARTNER],
  [SectionRole.McAddSurvey]: [ROLE.OPERATOR, ROLE.PARTNER],
  [SectionRole.McAddDashboardElement]: [ROLE.OPERATOR, ROLE.PARTNER],

  [SectionRole.MaCard]: [ROLE.PARTNER, ROLE.OPERATOR],
  [SectionRole.MaStructureList]: [ROLE.ADMIN],
  [SectionRole.MaCreation]:[ROLE.OPERATOR],
  [SectionRole.MaFill]: [ROLE.STAKEHOLDER],
  [SectionRole.MaTopic]: [ROLE.ADMIN],
  [SectionRole.MaSurveyList]: [ROLE.OPERATOR, ROLE.ADMIN, ROLE.PARTNER],

  [SectionRole.NfrCard]: [ROLE.PARTNER, ROLE.OPERATOR],
}

const adminRole = [ROLE.SYSTEM_ADMIN];
export const checkSectionRole = (sectionRole: SectionRole, currentRole: ROLE, type: 'action' | 'page' = 'page') => {

  const arr: ROLE[] = SectionRoleDict[sectionRole];
  if (arr?.length === 0 && type === 'page') return true;
  return arr?.includes(currentRole!);
}

export const checkHaveServices = (targetService: SERVICE, currentUser: IUser): boolean => {
  if (!environment.SERVICE_ENABLED.includes(targetService)) return false;
  //if ([...adminRole, ROLE.PARTNER].includes(currentUser.role!)) return true;
  if ((currentUser?.customer?.contracts?.length || 0) > 0) {
    return !!currentUser.customer?.contracts!.some((el: IContract) => el.services.includes(targetService));
  }
  return false;
}
