<form [formGroup]="formName">
  <div class="flex my-4 {{field.alignCenter && 'align-items-center'}} flex-{{field.direction || 'row'}} gap-3">
    <ng-container  *ngIf="field.multiple; else single">
      <div class="flex flex-column mb-4 gap-2">
      <label [for]="field.fieldName" class=" font-semibold">{{field.label}}</label>
      <p-tag value="Risposta Multipla"></p-tag>
    </div>
      <div *ngFor="let category of field.options!()" class="flex align-items-center gap-1 mb-3">
        <p-checkbox
          [inputId]="category.id"
          [value]="field.optionValue ? category[field.optionValue] : category"
          [formControlName]="field.fieldName!"
        ></p-checkbox>
        <label [for]="category.id" class="ml-2">{{ category[field.optionLabel!] }}</label>
      </div>
    </ng-container>
    <ng-template #single>
      <label [for]="field.fieldName">{{field.label}}</label>
      <p-inputSwitch [formControlName]="field.fieldName!" [inputId]="field.fieldName"></p-inputSwitch>
    </ng-template>
  </div>
</form>

