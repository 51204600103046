<form [formGroup]="formName">
  <div class="flex gap-2 flex-column mt-2 col-{{field.col}}">
    <label [for]="field.fieldName">{{field.label}}</label>
    @if(field.group && field.group()){ 
      <p-dropdown
      [formControlName]="field['fieldName']!"
      placeholder="Seleziona un valore"
      [options]="field.options!()"
      [group]="true"
      [optionLabel]="field.optionLabel || 'value'"
      [optionValue]="field.optionValue || undefined"></p-dropdown>
  }@else{

      <p-dropdown
      [formControlName]="field['fieldName']!"
      placeholder="Seleziona un valore"
      [options]="field.options!()"
      [optionLabel]="field.optionLabel || 'value'"
      [optionValue]="field.optionValue || undefined"></p-dropdown>
  }
</div>
</form>
