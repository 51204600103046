import {Injectable} from '@angular/core';
import {environment} from "src/environments/environment";
import {HttpClient} from "@angular/common/http";
import {IRegion} from "src/app/shared/models/localization/region.interface";
import {IProvince} from "src/app/shared/models/localization/province.interface";
import {IMunicipality} from "src/app/shared/models/localization/municipality.interface";

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  private path: string = `${environment.BASE_URL}localization`

  constructor(
    private http: HttpClient,
  ) {
  }

  getRegions() {
    return this.http.get<IRegion[]>(`${this.path}/regions`);
  }

  getProvinces(nuts2: string) {
    return this.http.get<IProvince[]>(`${this.path}/provinces/${nuts2}`)
  }

  getMunicipalities(nuts3: string) {
    return this.http.get<IMunicipality[]>(`${this.path}/municipalities/${nuts3}`)
  }

  getMunicipalitiesFiltered(name: string) {
    return this.http.get<IMunicipality[]>(`${this.path}/municipalities`, {
      params: {
        filter: name
      }
    })
  }
}
