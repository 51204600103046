import {SERVICE} from "@shared/enums/service.enum";

export const RoutingPathConfig = {
  LOGIN: 'login',
  NOT_FOUND: 'not-found',
  ACCESS_DENIED: 'access-denied',
  ERROR: 'error',
  USER: 'user',
  CUSTOMERS:'customers',
  PARTNERS:'partners',
  DASHBOARD:'dashboard',
  SUPPORT: 'support',
  ADMIN: 'admin',
  CONFIRM_REGISTRATION: 'confirm-registration',
  ESG_ASSESTMENT: SERVICE.ESG_ASSESSMENT,
  MANAGEMENT_CONTROL: SERVICE.MANAGEMENT_CONTROL,
  MATERIALITY_ANALYSIS: SERVICE.MATERIALITY_ANALYSIS
}


