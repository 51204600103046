import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-dynamic-error",
  templateUrl: "./dynamic-error.component.html",
  styleUrls: ["./dynamic-error.component.scss"],
  standalone: true,
  imports: [CommonModule]
})
export class DynamicErrorComponent implements OnInit {
  formName!: FormGroup;
  @Input({required: true}) fieldName!: string;
  @Input({required: true}) label!: string;

  constructor(private formgroupDirective: FormGroupDirective) {}

  ngOnInit() {
    this.formName = this.formgroupDirective.control;
  }
}
