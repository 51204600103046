import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {IFormQuestionModel} from "src/app/shared/components/dynamic-form/models/form-model.interface";
import {CalendarModule} from "primeng/calendar";

@Component({
  selector: 'app-dynamic-date',
  standalone: true,
  imports: [CommonModule, FormsModule, InputTextModule, ReactiveFormsModule, CalendarModule],
  templateUrl: './dynamic-date.component.html',
  styleUrls: ['./dynamic-date.component.scss']
})
export class DynamicDateComponent {
  @Input({required: true}) field!: IFormQuestionModel;
  formName: FormGroup;

  constructor(private formgroupDirective: FormGroupDirective) {
    this.formName = formgroupDirective.control;
  }
}
