import {APP_INITIALIZER, ApplicationConfig} from '@angular/core';
import {provideRouter, withHashLocation, withViewTransitions} from '@angular/router';
import {routes} from './app.routes';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi} from "@angular/common/http";
import {ResponseInterceptor} from "src/app/shared/interceptors/response.interceptor";
import {TokenInterceptor} from "src/app/shared/interceptors/token.interceptor";
import {ConfirmationService, MessageService} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {provideAnimations} from "@angular/platform-browser/animations";
import {TenantService} from "@shared/services/tenant.service";
import {spinnerInterceptor} from "@shared/interceptors/spinner.interceptor";

export function initializeApp(tenantService: TenantService) {
  return (): Promise<any> => tenantService.getTenant()
}

export let appConfig: ApplicationConfig;
appConfig = {
  providers: [
    provideRouter(
      routes,
      withViewTransitions(),
      withHashLocation()
    ),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([spinnerInterceptor,])
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [TenantService],
    },
    provideAnimations(),
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    MessageService,
    DialogService,
    ConfirmationService
  ]
};
