import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {IFormQuestionModel} from "src/app/shared/components/dynamic-form/models/form-model.interface";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {IProvince} from "@shared/models/localization/province.interface";
import {IMunicipality} from "@shared/models/localization/municipality.interface";
import {LocalizationService} from "@shared/services/localization.service";
import {IRegion} from "@shared/models/localization/region.interface";
import {Dropdown, DropdownModule} from "primeng/dropdown";
import {distinctUntilChanged, skipWhile} from "rxjs";

@Component({
  selector: 'app-dynamic-localization',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DropdownModule],
  templateUrl: './dynamic-localization.component.html',
  styleUrls: ['./dynamic-localization.component.scss']
})
export class DynamicLocalizationComponent implements OnInit{
  @Input({required: true}) field!: IFormQuestionModel;
  @ViewChild('provinceSelect') provinceSelect!: Dropdown;
  formName: FormGroup;
  regions$ = this.localizationService.getRegions();
  provinces: IProvince[] = []
  municipalities: IMunicipality[] = []
  fg!: FormGroup;

  constructor(private formgroupDirective: FormGroupDirective, private localizationService: LocalizationService) {
    this.formName = formgroupDirective.control;

  }

  ngOnInit() {
    this.inizializeForm(this.field.value);

    this.fg.get('region')?.valueChanges.pipe(
      distinctUntilChanged(),
      skipWhile(value => !value)
    ).subscribe((value: IRegion) => this.getProvinces(value.nuts2 || ''));
    this.fg.get('province')?.valueChanges.pipe(
      distinctUntilChanged(),
      skipWhile(value => !value)
    ).subscribe((value: IProvince) => this.getMunicipalities(value.nuts3 || ''));

    this.formName.get(this.field['fieldName']!)?.valueChanges.subscribe(value => this.inizializeForm(value))
  }


  inizializeForm(localization?: IMunicipality) {
    const checkDisabled = !!this.formName.get(this.field['fieldName']!)?.disabled
    this.fg = new UntypedFormGroup({
      region: new FormControl({value: null, disabled: checkDisabled}),
      province: new FormControl({value: null, disabled: checkDisabled}),
      municipality: new FormControl({value: null, disabled: checkDisabled}, [Validators.required]),
    })

    if (this.fg && localization?.municipality) {
      this.fg.get('region')?.setValue(localization.province?.region);
      this.localizationService.getProvinces(localization.province?.region?.nuts2!)
        .subscribe((res: IProvince[]) => {
          this.provinces = res;
          const province = this.provinces.find(el => el.nuts3 === localization.province?.nuts3)
          this.fg.get('province')?.setValue(province);
        });

      this.localizationService.getMunicipalities(localization.province?.nuts3 || '')
        .subscribe((res: IMunicipality[]) => {
          this.municipalities = res;
          const municipality = this.municipalities.find(el => el.istatCode === localization.istatCode)
          this.fg.get('municipality')?.setValue(municipality);
        });

      this.getMunicipalities(localization.province?.nuts3 || '')
    }

  }

  onChangeMunicipality(municipality: IMunicipality){
    this.formName.get(this.field['fieldName']!)?.setValue(municipality, { emitEvent: false });
  }

  getProvinces(nuts2: string) {
    this.localizationService.getProvinces(nuts2).subscribe((res: IProvince[]) => this.provinces = res);
  }

  getMunicipalities(nuts3: string) {
    this.localizationService.getMunicipalities(nuts3).subscribe((res: IMunicipality[]) => this.municipalities = res);
  }

  onClearRegion() {
    this.fg.get('province')?.setValue(null);
    this.provinces = [];
    this.onClearProvince();
  }

  onClearProvince() {
    this.fg.get('municipality')?.setValue(null);
    this.municipalities = [];
  }
}
