import {Routes} from "@angular/router";
import {RoutingPathConfig} from "@shared/configs/routing-path.config";
import {LayoutComponent} from "@shared/components/layout/layout.component";
import {authGuard} from "@shared/guards/auth.guard";
import {roleGuard} from "@shared/guards/role.guard";
import {SectionRole} from "@shared/configs/role.config";
import {NotFoundComponent} from "src/app/pages/not-found/not-found.component";
import {SERVICE} from "@shared/enums/service.enum";
import {serviceEnabledGuard} from "@shared/guards/service-enabled.guard";


export const routes: Routes = [
  {
    path: RoutingPathConfig.ERROR,
    loadComponent: () => import('./pages/error/error.component').then(c => c.ErrorComponent),
  },
  {
    path: ':tenant',
    children: [
      {
        path: RoutingPathConfig.LOGIN,
        loadComponent: () => import('./pages/login/login.component').then(c => c.LoginComponent)
      },
      {
        path: RoutingPathConfig.CONFIRM_REGISTRATION + '/:confirmationToken',
        loadComponent: () => import('./pages/confirm-registration/confirm-registration.component').then(c => c.ConfirmRegistrationComponent),
      },
      {
        path: RoutingPathConfig.ACCESS_DENIED,
        loadComponent: () => import('./pages/access-denied/access-denied.component').then(c => c.AccessDeniedComponent),
      },
      {
        path: RoutingPathConfig.NOT_FOUND,
        loadComponent: () => import('./pages/not-found/not-found.component').then(c => c.NotFoundComponent),
      },
      {
        path: '',
        children: [
          {
            path: RoutingPathConfig.DASHBOARD,
            loadComponent: () => import('./pages/dashboard/dashboard.component').then(c => c.DashboardComponent)
          },
          {
            path: RoutingPathConfig.USER,
            loadComponent: () => import('./pages/user/user.component').then(c => c.UserComponent)
          },
          {
            path: RoutingPathConfig.ADMIN,
            loadChildren: () => import('./features/admin/admin.routes').then(r => r.routes),
          },
          {
            path: RoutingPathConfig.CUSTOMERS,
            loadChildren: () => import('./pages/clients/clients.routes').then(r => r.routes),
            data: {
              section: SectionRole.ClientList,
              isPartner: false
            },
            canActivate: [roleGuard]
          },
          {
            path: RoutingPathConfig.PARTNERS,
            loadChildren: () => import('./pages/clients/clients.routes').then(r => r.routes),
            data: {
              section: SectionRole.ClientList,
              isPartner: true,
            },
            canActivate: [roleGuard]
          },
          {
            path: RoutingPathConfig.ESG_ASSESTMENT,
            loadChildren: () => import('./features/esg-assessment/esg-assessment.routes').then(r => r.routes),
            data: {
              service: SERVICE.ESG_ASSESSMENT
            },
            canActivate: [serviceEnabledGuard]
          },
          {
            path: RoutingPathConfig.MANAGEMENT_CONTROL,
            loadChildren: () => import('./features/management-control/management-control.routes').then(r => r.routes),
            data: {
              service: SERVICE.MANAGEMENT_CONTROL
            },
            canActivate: [serviceEnabledGuard]
          },
          {
            path: RoutingPathConfig.MATERIALITY_ANALYSIS,
            loadChildren: () => import('./features/materiality-analysis/materiality-analysis.routes').then(r => r.routes),
            data: {
              service: SERVICE.MATERIALITY_ANALYSIS
            },
            canActivate: [serviceEnabledGuard]
          },
          {path: '', redirectTo: RoutingPathConfig.DASHBOARD, pathMatch: "full"}
        ],
        component: LayoutComponent,
        canActivate: [authGuard],
      },
      {path: '**', pathMatch: 'full', component: NotFoundComponent},
    ],
  },
];
