import { AfterViewInit, Component, Input } from "@angular/core";
import {FormGroup, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";
import {IFormQuestionModel} from "@shared/components/dynamic-form/models/form-model.interface";
import {CommonModule} from "@angular/common";
import {InputTextModule} from "primeng/inputtext";
import {DropdownModule} from "primeng/dropdown";


@Component({
  selector: "app-dynamic-select",
  templateUrl: "./dynamic-select.component.html",
  styleUrls: ["./dynamic-select.component.css"],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    DropdownModule
  ]
})
export class DynamicSelectComponent implements AfterViewInit {
  @Input({required: true}) field!: IFormQuestionModel;
  formName: FormGroup;
  alive = true;

  constructor(
    private formGroupDirective: FormGroupDirective) {
    this.formName = formGroupDirective.control;
  }
  
  ngAfterViewInit(): void {
    this.listenForLinkData();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  listenForLinkData() {
    if (!this.field?.link) {
      return;
    }
  }

  changedValue(value: string) {
    if (!this.field.provideData) {
      return;
    }
  }
}
