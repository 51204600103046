import {CanActivateFn, Router} from '@angular/router';
import {SERVICE} from "@shared/enums/service.enum";
import {environment} from "../../../environments/environment";
import {RoutingPathConfig} from "@shared/configs/routing-path.config";
import {inject} from "@angular/core";
import {ErrorService} from "@shared/services/error.service";

export const serviceEnabledGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const errorService = inject(ErrorService);

  const service: SERVICE = route.data['service'] as SERVICE;

  if (service && !environment.SERVICE_ENABLED.includes(service)) {
    errorService.setAccessDeniedError('SERVICE NOT ENABLED');
    return router.createUrlTree([RoutingPathConfig.ACCESS_DENIED]);
  }

  return true;
};
