import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../services/auth.service";
import {checkSectionRole, SectionRole} from "@shared/configs/role.config";
import {RoutingPathConfig} from "@shared/configs/routing-path.config";
import {ErrorService} from "@shared/services/error.service";

export const roleGuard: CanActivateFn = (route, state) => {

  const section: SectionRole = route.data['section'];
  const errorService = inject(ErrorService);

  const authService = inject(AuthService);
  const router = inject(Router);

  const currentUserRole = authService.currentUserRole();

  if (!section) {
    return true;
  }

  if (!checkSectionRole(section, currentUserRole!)) {
    errorService.setAccessDeniedError('ROLE NOT ALLOWED');
    return router.createUrlTree([RoutingPathConfig.ACCESS_DENIED]);
  }

  return true;
};
