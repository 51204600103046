<ng-template [ngIf]="fg">
  <form [formGroup]="fg">
    <!-- <label class="mb-4"[for]="field.fieldName">{{ field.label }}</label> -->
    <div class="grid my-2">
      <div class="flex flex-column col-4 gap-2">
        <label for="region">Regione</label>
        <div >
          <p-dropdown
            [options]="(regions$ | async) || []"
            optionLabel="region"
            inputId="region"
            [showClear]="true"
            formControlName="region"
            (onClear)="onClearRegion()"
            placeholder="Seleziona"
            [filter]="true"
            filterBy="region"
          ></p-dropdown>
        </div>
      </div>
      <div class="flex flex-column col-4 gap-2">
        <label for="province">Provincia</label>
        <div >
          <p-dropdown
            [options]="provinces"
            optionLabel="province"
            formControlName="province"
            inputId="province"
            [showClear]="true"
            (onClear)="onClearProvince()"
            placeholder="Seleziona"
            [filter]="true"
            filterBy="province"
          ></p-dropdown>
        </div>
      </div>
      <div class="flex flex-column col-4 gap-2">
        <label for="municipality">Comune</label>
        <div >
          <p-dropdown
            [options]="municipalities"
            optionLabel="municipality"
            inputId="municipality"
            formControlName="municipality"
            placeholder="Seleziona"
            [filter]="true"
            filterBy="municipality"
            (onChange)="onChangeMunicipality($event.value)"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </form>
</ng-template>
