import {inject, Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode
} from '@angular/common/http';
import {catchError, map, Observable, throwError} from 'rxjs';
import {MessageService} from "primeng/api";
import {Router} from "@angular/router";
import {RoutingPathConfig} from "@shared/configs/routing-path.config";
import {AuthService} from "@shared/services/auth.service";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  #router = inject(Router);
  #authService = inject(AuthService);
  constructor(private messageService: MessageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event = event.clone({body: event.body.data});
          if (req.method !== 'GET' && !req.headers.get("skip")) {
            const message = event.body.message ? event.body.message : 'Operazione eseguita con successo';
            this.messageService.add({severity: 'success', summary: 'Fatto', detail: message})
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if ([HttpStatusCode.Forbidden].includes(error.status)) {
          this.#router.navigate([`./${RoutingPathConfig.ACCESS_DENIED}`])
        }
        if ([HttpStatusCode.Unauthorized].includes(error.status)) {
          this.#authService.logout();
        }
        if (req.method !== 'GET') {
          const message = error.error.message ? error.error.message : 'Si è verificato un errore';
          if (Array.isArray(message)) {
            message.forEach(el => this.messageService.add({severity: 'error', summary: 'Errore', detail: el}))
          } else
            this.messageService.add({severity: 'error', summary: 'Errore', detail: message})
        }

        return throwError(() => error);
      })
    );

  }
}
