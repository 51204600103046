import {Component, ElementRef, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterOutlet} from "@angular/router";
import {SidemenuComponent} from "../sidebar/sidemenu.component";
import {SpinnerComponent} from "../spinner/spinner.component";
import {ToastModule} from "primeng/toast";
import {CtxService} from "../../services/ctx.service";
import {HeaderComponent} from "src/app/shared/components/header/header.component";
import {SidebarModule} from 'primeng/sidebar';
import {OverlaySidebarComponent} from '@shared/components/overlay-sidebar/overlay-sidebar.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [CommonModule, HeaderComponent, RouterOutlet, SidemenuComponent, SpinnerComponent, ToastModule, SidebarModule, OverlaySidebarComponent],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  @ViewChild('layout', {static: false}) layout!: ElementRef;

  isSideBarOpen = this.ctxService.displaySideBar

  constructor(private ctxService: CtxService) {
  }
}
