import {HttpErrorResponse, HttpEvent, HttpInterceptorFn} from '@angular/common/http';
import {environment} from "src/environments/environment";
import {catchError, debounceTime, tap, throwError} from "rxjs";
import {inject} from "@angular/core";
import {CtxService} from "@shared/services/ctx.service";

export const spinnerInterceptor: HttpInterceptorFn = (request, next) => {

  // array delle chiamate ajax ancora in attesa di risposta
  const ongoing: string[] = [];

  // array con i servizi che non devono mostrare lo spinner quando vengono chiamati
  const spinnerCallExceptions: string[] = [];

  const ctxService = inject(CtxService);

  if (request.url.includes(environment.BASE_URL)) {
    ctxService.startLoading();
    ongoing.push(request.url);
  }

  return next(request).pipe(
    debounceTime(2000),
    tap((event: HttpEvent<any>) => {
      if (event.type === 4) {
        ongoing.splice(ongoing.indexOf(request.url), 1);
      }
      if (!ongoing.length) {
        ctxService.finishLoading();
      }
    }),
    catchError((error: HttpErrorResponse) => {
        ongoing.splice(ongoing.indexOf(request.url), 1);
        if (!ongoing.length) {
          ctxService.finishLoading();
        }
        return throwError(() => error);
      }
    )
  );
};
