import {
  DynamicInputComponent
} from "src/app/shared/components/dynamic-form/dynamic-field/dynamic-input/dynamic-input.component";
import {
  DynamicSelectComponent
} from "src/app/shared/components/dynamic-form/dynamic-field/dynamic-select/dynamic-select.component";
import {
  DynamicRadioComponent
} from "src/app/shared/components/dynamic-form/dynamic-field/dynamic-radio/dynamic-radio.component";
import {
  DynamicDateComponent
} from "src/app/shared/components/dynamic-form/dynamic-field/dynamic-date/dynamic-date.component";
import {
  DynamicCheckboxsComponent
} from "src/app/shared/components/dynamic-form/dynamic-field/dynamic-checkboxs/dynamic-checkboxs.component";
import {
  DynamicLocalizationComponent
} from "src/app/shared/components/dynamic-form/dynamic-field/dynamic-localization/dynamic-localization.component";
import {
  DynamicCurrencyComponent
} from "src/app/shared/components/dynamic-form/dynamic-field/dynamic-currency/dynamic-currency.component";
import {Type} from "@angular/core";
import {IFormQuestionType} from "src/app/shared/components/dynamic-form/models/form-model.interface";
import {
  DynamicMultiselectComponent
} from "src/app/shared/components/dynamic-form/dynamic-field/dynamic-multiselect/dynamic-multiselect.component";
import {DynamicFileComponent} from "@shared/components/dynamic-form/dynamic-field/dynamic-file/dynamic-file.component";
import {DynamicFormulaComponent} from "./dynamic-formula/dynamic-formula.component";
import {DynamicOtpComponent} from "@shared/components/dynamic-form/dynamic-field/dynamic-otp/dynamic-otp.component";


export type DynamicFieldComponentType =
  DynamicInputComponent
  | DynamicSelectComponent
  | DynamicRadioComponent
  | DynamicDateComponent
  | DynamicCheckboxsComponent
  | DynamicLocalizationComponent
  | DynamicCurrencyComponent
  | DynamicMultiselectComponent
  | DynamicFileComponent
  | DynamicFormulaComponent
  | DynamicOtpComponent

export const dynamicFieldComponentFactory: Record<IFormQuestionType, Type<DynamicFieldComponentType>> = {
  'text': DynamicInputComponent,
  'email': DynamicInputComponent,
  'dropdown': DynamicSelectComponent,
  'localization': DynamicLocalizationComponent,
  'number': DynamicInputComponent,
  'date': DynamicDateComponent,
  'radio': DynamicRadioComponent,
  'checkbox': DynamicCheckboxsComponent,
  'currency': DynamicCurrencyComponent,
  'multiselect': DynamicMultiselectComponent,
  'file': DynamicFileComponent,
  'formula': DynamicFormulaComponent,
  'textarea': DynamicInputComponent,
  'otp': DynamicOtpComponent
};
