import {Component, Input} from "@angular/core";
import {FormGroup, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {IFormQuestionModel} from "@shared/components/dynamic-form/models/form-model.interface";
import {InputNumberModule} from "primeng/inputnumber";
import {CommonModule} from "@angular/common";
import {InputTextareaModule} from "primeng/inputtextarea";

@Component({
  selector: "app-dynamic-input",
  templateUrl: "./dynamic-input.component.html",
  styleUrls: ["./dynamic-input.component.css"],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule
  ],
  standalone: true
})
export class DynamicInputComponent {
  @Input({required: true}) field!: IFormQuestionModel;
  formName: FormGroup;

  constructor(private formgroupDirective: FormGroupDirective) {
    this.formName = formgroupDirective.control;
  }
}
