<form [formGroup]="formName">
    <div class="flex flex-column gap-2 mt-2">
        <label [for]="field.fieldName">{{ field.label }}</label>
        <div class="flex gap-2">
            @for(item of field.operators; track item.label){
              <button pButton (click)="setInputValue(item.value)" [disabled]="formName.disabled">{{ item.label }}
              </button>
            }
        </div>
        <input [formControlName]="field.fieldName!" [id]="field.fieldName" [type]="field.type" pInputText />
    </div>
</form>
