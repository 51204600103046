import {computed, Injectable, signal} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CtxService {
  #displaySideBar = signal(true);
  displaySideBar = computed(()=>this.#displaySideBar());
  _displayLoadingBS$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  displayLoading$: Observable<boolean> = this._displayLoadingBS$.asObservable();
  _pageTitleBS$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  pageTitle$: Observable<string> = this._pageTitleBS$.asObservable();


  toggleSideBar(state:boolean){
    this.#displaySideBar.set(state);
  }

  startLoading() {
    this._displayLoadingBS$.next(true);
  }

  finishLoading() {
    this._displayLoadingBS$.next(false);
  }

  updatePageTitle(title: string) {
    this._pageTitleBS$.next(title);
  }

}
