import {computed, inject, Injectable, signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {ITenant} from "@shared/models/tenant.interface";
import {Router} from "@angular/router";
import {RoutingPathConfig} from "@shared/configs/routing-path.config";

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  #router = inject(Router)

  tenant = signal<ITenant | null>(null);
  logo = computed(() => {
    let logo;
    if (this.tenant()?.logo instanceof String) {
      logo = this.tenant()?.logo
    } else {
      const unit8Array = new Uint8Array((this.tenant()?.logo as any).data)
      logo = URL.createObjectURL(new Blob([unit8Array.buffer], {type: 'image/*'}))
    }
    return logo;
  })
  private path: string = `${environment.BASE_URL}tenant`;
  tenantList = signal<ITenant[]>([]);

  constructor(private http: HttpClient) {}

  setTenant(tenant?: ITenant) {
    if (!!this.tenant() && this.tenant()?.path === tenant?.path) {
      return;
    }

    if (tenant && this.checkTenantExist(tenant)) {
      this.tenant.set(tenant)
    } else {
      this.tenant.set(this.checkTenantExist({path: environment.DEFAULT_TENANT})!)
    }
  }

  checkTenantExist(tenant: ITenant) {
    return this.tenantList().find(el => el.path === tenant.path);
  }

  getTenant() {
    return new Promise<void>((resolve, reject) => {
      this.http.get<ITenant[]>(`${this.path}`).subscribe({
        next: res => {
          this.tenantList.set(res);
          resolve();
        },
        error: err => {
          this.#router.navigate([RoutingPathConfig.ERROR])
          reject(err)
        }
      })
    })
  }
}
