import {Component, inject, signal} from '@angular/core';
import {IFormModel} from "@shared/components/dynamic-form/models/form-model.interface";
import {DynamicFormComponent} from "@shared/components/dynamic-form/dynamic-form.component";
import {SupportService} from "@shared/services/support.service";
import {DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-ticket-modal',
  standalone: true,
  imports: [
    DynamicFormComponent
  ],
  template: `
    <app-dynamic-form [model]="model" (onSubmit)="submit($event)"></app-dynamic-form>
  `,
  styles: ``
})
export class TicketModalComponent {

  #supportService = inject(SupportService);
  #ref = inject(DynamicDialogRef)

  type = signal([
    {id: 'question', value: 'Domanda'},
    {id: 'incident', value: 'Errore'},
    {id: 'problem', value: 'Problema'},
  ])

  model: IFormModel = {
    type: {
      type: 'dropdown',
      value: null,
      label: 'Tipologia',
      col: 'col-4',
      options: this.type,
      optionLabel: 'value',
      optionValue: 'id',
      order: 3,
    },
    subject: {
      type: "text",
      value: '',
      label: "Oggetto",
      col: "col-8",
      rules: {
        required: true,
      },
      order: 4,
    },
    description: {
      type: "textarea",
      value: '',
      label: "Descrizione",
      col: "col-12",
      rules: {
        required: true,
      },
      order: 5,
    },
  }

  submit(ticket: any) {
    this.#supportService.createTicket(ticket).subscribe(() => this.#ref.close());
  }
}
