<app-header></app-header>
<div class="main-container ">
  <div class="layout-sidebar" [ngClass]="{'open' : isSideBarOpen()}">
    <app-sidemenu></app-sidemenu>
  </div>
  <div class="layout-overlay-sidebar">
    <app-overlay-sidebar></app-overlay-sidebar>
  </div>
  <div class="layout-content" [ngClass]="{'open' : !isSideBarOpen()}">
    <div #layout class="bg-white border-round-xl p-4 h-full overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<footer class="p-2 text-sm flex items-center justify-content-center">
  <span>Made by <a href="https://www.bittag.it" target="_blank">BitTag</a></span>
</footer>

