import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="splash-loader"></div>
    <div id="overlay"></div>
  `,
  styles: [
    `.splash-loader,
    .splash-loader:before,
    .splash-loader:after {
      left: 50%;
      top: 50%;
      margin-left: -45px;
      margin-top: -45px;
      position: absolute;
      vertical-align: middle;
      background: var(--primary-color);
      width: 90px;
      height: 90px;
      border-radius: 50%;
      z-index: 99999;
    }

    .splash-loader:before {
      content: "";
      animation: bounce 1.5s infinite;
    }

    .splash-loader:after {
      content: "";
      animation: bounce 1.5s -0.4s infinite;
    }

    @keyframes bounce {
      0% {
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        opacity: 1;
      }
      100% {
        transform: scale(2);
        -webkit-transform: scale(2);
        opacity: 0;
      }
    }

    @-webkit-keyframes bounce {
      0% {
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        opacity: 1;
      }
      100% {
        transform: scale(2);
        -webkit-transform: scale(2);
        opacity: 0;
      }
    }

    .splash-screen {
      background-color: #f3f3f3;
      height: 100%;
    }

    #overlay {
      position: fixed; /* Sit on top of the page content */
      width: 100%; /* Full width (cover the whole page) */
      height: 100%; /* Full height (cover the whole page) */
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4); /* Black background with opacity */
      z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
      cursor: pointer; /* Add a pointer on hover */
    }
    `
  ]
})
export class SpinnerComponent {

}
