import {Component, Input} from '@angular/core';
import {FileUploadModule} from "primeng/fileupload";
import {InputNumberModule} from "primeng/inputnumber";
import {InputTextModule} from "primeng/inputtext";
import {NgSwitchCase} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";
import {IFormQuestionModel} from "@shared/components/dynamic-form/models/form-model.interface";
import {FormGroup, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-dynamic-file',
  standalone: true,
  imports: [
    FileUploadModule,
    InputNumberModule,
    InputTextModule,
    NgSwitchCase,
    PaginatorModule,
    ReactiveFormsModule
  ],
  template: `
      <form [formGroup]="formName">
          <div class="flex flex-column gap-2 mt-2">
              <label [for]="field.fieldName">{{field.label}}</label>
              <label for="file">Scegli file</label>
              <input type="file" id="file" (change)="add($any($event.target).files)" accept="{{field.fileType}}">
          </div>
      </form>
  `,
  styles: ``
})
export class DynamicFileComponent {

  @Input({required: true}) field!: IFormQuestionModel;
  formName: FormGroup;

  constructor(private formgroupDirective: FormGroupDirective) {
    this.formName = formgroupDirective.control;
  }

  add(file: any) {
    this.formName.get(this.field['fieldName']!)?.setValue(file, {emitEvent: false});
  }
}
