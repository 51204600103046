import { Component, Input, inject } from '@angular/core';
import { IFormQuestionModel } from '../../models/form-model.interface';
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-dynamic-formula',
  standalone: true,
  imports: [ReactiveFormsModule, InputTextModule, ButtonModule],
  templateUrl: './dynamic-formula.component.html',
  styleUrl: './dynamic-formula.component.scss'
})
export class DynamicFormulaComponent {
  @Input({ required: true }) field!: IFormQuestionModel;
  formGroupDirective = inject(FormGroupDirective)
  formName: FormGroup = this.formGroupDirective.control;

  setInputValue(value: string) {
    let inputValue = this.formName.value.value;
    this.formName.patchValue({ value: inputValue ? inputValue + value : value })
  }
}
