<form [formGroup]="formName">
  <div class="flex flex-column gap-2 mt-2">
    <label [for]="field.fieldName">{{ field.label }}</label>
    <ng-container [ngSwitch]="field.type">
      <input
        *ngSwitchCase="'text'"
        [formControlName]="field.fieldName!"
        [id]="field.fieldName"
        [type]="field.type"
        pInputText/>
      <p-inputNumber
        *ngSwitchCase="'number'"
        [formControlName]="field.fieldName!"
        [id]="field.fieldName"
        [maxFractionDigits]="1"
        mode="decimal"/>
      <textarea
        rows="5"
        class="w-full"
        pInputTextarea
        *ngSwitchCase="'textarea'"
        [formControlName]="field.fieldName!"
        [id]="field.fieldName"></textarea>
    </ng-container>
  </div>
</form>
