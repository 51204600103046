<form [formGroup]="formName">
  <div class="field col-{{field.col}} mb-6">
    <label [for]="field.fieldName" class="mb-4 font-semibold">{{field.label}}</label>
    <div *ngFor="let category of field.options!()" class="flex gap-1 align-items-center mb-3">
      <p-radioButton [inputId]="category.id" [value]="field.optionValue ? category[field.optionValue] : category"
                     [formControlName]="field.fieldName!"></p-radioButton>
      <label [for]="category.id" class="ml-2">{{ category[field.optionLabel!] }}</label>
    </div>
  </div>
</form>

