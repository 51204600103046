import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private _token: string = '';

  constructor() {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    this._token = localStorage.getItem(environment.ACCESS_TOKEN) || ''

    const newRequest = request.clone({
      setHeaders: {
        'authentication-token': `${this._token}`,
      }
    });
    return next.handle(newRequest);
  }
}
