<form [formGroup]="dynamicFormGroup" (ngSubmit)="submit()" class="formgrid grid">
  <div *ngFor="let field of fields" [class]="field.col">
    <app-field-input [field]="field"></app-field-input>
    <div class="relative h-1rem mt-2" *ngIf="!field.hideError">
      <app-dynamic-error [fieldName]="field['fieldName']!" [label]="field['label']"></app-dynamic-error>
    </div>
    <ng-content #otherInputs></ng-content>
  </div>
  <div class="col-12 mt-4 flex gap-2" *ngIf="showActions">
    <button pButton label="Annulla" type="button" (click)="delete()"
      class="p-button-outlined p-button-secondary"></button>
    <button pButton [label]="submitLabel" type="submit" icon="pi pi-{{submitIcon}}"
      [disabled]="!dynamicFormGroup.valid"></button>
  </div>
</form>